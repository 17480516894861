import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  NavigationTarget,
  trackNavigationElementOpen,
} from '@hotelplan/fdr.lib.track.header';
import { ShareEventType, trackShare } from '@hotelplan/fdr.lib.track.meta';
import {
  FdrShareDetails,
  FdrShareForm,
} from '@hotelplan/fdr.regular.fusion.fdr-share-form';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { isIOS } from '@hotelplan/libs.utils';
import { ShareFormModalWrapper } from 'fdr/components/local/fdr-site-tool-buttons-mobile/share/fdr-share-site-tool-mobile.styles';
import { IconButton } from 'fdr/components/local/fdr-site-tool-buttons/fdr-site-tool-buttons.styles';
import { FdrSiteToolTooltip } from 'hp-mf/fdr/components/fdr-site-tool-tooltip';

export const FdrShareSiteTool = () => {
  const [t] = useTranslation('common');

  const [
    isShareFormModalShowed,
    setShareFormModalOpen,
    setShareFormModalClose,
  ] = useToggleState(false);

  function onShareClick() {
    trackShare(ShareEventType.OPEN);
    trackNavigationElementOpen(NavigationTarget.SHARING);
    setShareFormModalOpen();
  }

  return (
    <>
      <FdrSiteToolTooltip text={t('share.tooltip')}>
        <IconButton
          active={false}
          testId={'header-share-buttons'}
          icon={{ name: isIOS ? 'share-ios' : 'share-android' }}
          onClick={onShareClick}
        >
          {t('share.button')}
        </IconButton>
      </FdrSiteToolTooltip>
      <ShareFormModalWrapper
        name="shareForm"
        title={t('shareModal.title')}
        show={isShareFormModalShowed}
        onClose={() => {
          trackShare(ShareEventType.CLOSE);
          setShareFormModalClose();
        }}
      >
        <FdrShareDetails />
        <FdrShareForm />
      </ShareFormModalWrapper>
    </>
  );
};
