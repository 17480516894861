import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { Greetings } from '@hotelplan/components.common.greetings';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH } from '@hotelplan/fdr.lib.ident.auth-config';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useScrollElement } from '@hotelplan/libs.context.ios-scroll-block';
import { FdrGreetingsSkeleton } from 'hp-mf/fdr/components/fdr-my-account/fdr-account-greetings';
import {
  FdrAccountHeaderMenuItems,
  FdrAccountHeaderMenuLogoutButton,
  FdrAccountHeaderMenuWrapper,
} from './fdr-account-header-menu.styles';
import { getMenuItems } from './fdr-account-header-menu.utils';

interface IAccountData {
  firstName: string;
  lastName: string;
}

interface IFdrAccountHeaderMenuProps {
  isOpened: boolean;
  accountData: IAccountData;
  accountDataLoading: boolean;
  onMenuItemClick?(): void;
}

export function FdrAccountHeaderMenu({
  isOpened,
  accountData,
  accountDataLoading,
  onMenuItemClick,
}: IFdrAccountHeaderMenuProps): React.ReactElement {
  const { push } = useRouter();
  const [t, { language }] = useTranslation(['common', 'myaccount']);
  const scrollElementRef = useRef<HTMLDivElement>(null);

  const menuItems = getMenuItems(language);

  async function logoutHandler() {
    push(SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH);
  }

  useScrollElement(scrollElementRef);

  if (!isOpened) return null;

  return (
    <FdrAccountHeaderMenuWrapper ref={scrollElementRef}>
      <Greetings
        greetingsTextTranslation={t('common:greetings')}
        firstName={accountData.firstName}
        lastName={accountData.lastName}
        loading={accountDataLoading}
        placeholder={<FdrGreetingsSkeleton />}
      />
      <FdrAccountHeaderMenuItems>
        {menuItems.map(({ label, routerProps }, index) => (
          <FdrRoute
            className="menu-item"
            link={routerProps}
            key={`${label}-${index}`}
          >
            <BsLink onClick={onMenuItemClick}>{t(label)}</BsLink>
          </FdrRoute>
        ))}
      </FdrAccountHeaderMenuItems>
      <FdrAccountHeaderMenuLogoutButton
        variant="linkBtn"
        icon={{ name: 'logout' }}
        onClick={logoutHandler}
      >
        {t('myaccount:logout')}
      </FdrAccountHeaderMenuLogoutButton>
    </FdrAccountHeaderMenuWrapper>
  );
}
