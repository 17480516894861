import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { TFdrTabsTable } from 'hp-mf/fdr/components/fdr-my-account/fdr-account-layout';

export const ACCOUNT_TABS_TABLE: TFdrTabsTable = {
  [EPageType.MyaccountMybookings]: { label: 'myaccount:myBooking' },
  [EPageType.Wishlist]: { label: 'myaccount:myWishlist' },
  [EPageType.MyaccountMyhistory]: { label: 'myaccount:myHistory' },
  [EPageType.MyaccountMyprofile]: {
    label: 'myaccount:myProfile',
    icon: 'user',
  },
};
