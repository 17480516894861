import { useTranslation } from 'next-i18next';
import React from 'react';
import { FdrSiteToolTooltip } from 'hp-mf/fdr/components/fdr-site-tool-tooltip';
import { SiteToolWishlistIcon } from './fdr-wishlist-site-tool.styles';

export const FdrWishlistSiteTool: React.FC = () => {
  const [t] = useTranslation('wishlist');

  return (
    <FdrSiteToolTooltip text={t('header.icon.tooltip')}>
      <SiteToolWishlistIcon />
    </FdrSiteToolTooltip>
  );
};
