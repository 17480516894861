import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import {
  NavigationTarget,
  trackNavigationElementOpen,
} from '@hotelplan/fdr.lib.track.header';
import { HistoryEventType, trackHistory } from '@hotelplan/fdr.lib.track.meta';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { IconButton } from 'fdr/components/local/fdr-site-tool-buttons/fdr-site-tool-buttons.styles';
import { FdrSiteToolTooltip } from 'hp-mf/fdr/components/fdr-site-tool-tooltip';
import {
  MySearchHistory,
  SearchHistoryWrapper,
} from './fdr-history-site-tool.styles';

const trackHistoryClick = (isSearchHistoryDropdownShown: boolean) => {
  if (isSearchHistoryDropdownShown) {
    trackHistory(HistoryEventType.CLOSE);
  } else {
    trackHistory(HistoryEventType.OPEN);
    trackNavigationElementOpen(NavigationTarget.HISTORY);
  }
};

export const FdrHistorySiteTool = () => {
  const [t] = useTranslation();

  const searchHistoryDropdownRef = useRef<HTMLDivElement>(null);
  const searchHistoryButtonRef = useRef<HTMLButtonElement>(null);

  const [
    isSearchHistoryDropdownShown,
    ,
    closeSearchHistoryDropdown,
    toggleSearchHistoryDropdown,
  ] = useToggleState(false);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [searchHistoryDropdownRef, searchHistoryButtonRef],
    () => {
      if (!isSearchHistoryDropdownShown) return;
      trackHistory(HistoryEventType.CLOSE);
      closeSearchHistoryDropdown();
    }
  );

  return (
    <>
      <FdrSiteToolTooltip text={t('common:history.tooltip')}>
        <IconButton
          active={false}
          testId={'header-history-buttons'}
          icon={{ name: 'history' }}
          ref={searchHistoryButtonRef}
          onClick={() => {
            trackHistoryClick(isSearchHistoryDropdownShown);
            toggleSearchHistoryDropdown();
          }}
        >
          {t('common:history.button')}
        </IconButton>{' '}
      </FdrSiteToolTooltip>
      {isSearchHistoryDropdownShown && (
        <SearchHistoryWrapper
          className="search-history"
          ref={searchHistoryDropdownRef}
        >
          <MySearchHistory
            onClick={() => {
              trackHistory(HistoryEventType.CLOSE);
              closeSearchHistoryDropdown();
            }}
          />
        </SearchHistoryWrapper>
      )}
    </>
  );
};
