import React from 'react';
import type { CSSObject } from 'styled-components';
import {
  NavigationTarget,
  trackNavigationElementOpen,
} from '@hotelplan/fdr.lib.track.header';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrWishlistIcon } from 'fdr/components/candidate/fdr-wishlist-icon';
import { useFdrWishlistIconQuery } from 'hp-mf/fdr/schemas/query/wishlist/fdr-wishlist-icon.generated';

interface IWishListIconContainer {
  className?: string;
  iconCss?: CSSObject;
  counterCss?: CSSObject;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const FdrWishlistIconContainer: React.FC<IWishListIconContainer> = ({
  className,
  iconCss,
  counterCss,
}) => {
  const { data: fdrIconData } = useFdrWishlistIconQuery({ ssr: false });

  const routerLink =
    fdrIconData?.fdrWishlistPage?.webMeta?.listWebMeta?.link || null;

  const handleWishlistIconClick = () => {
    trackNavigationElementOpen(NavigationTarget.WISHLIST);
  };

  const wishListIconProps = {
    iconCss: iconCss,
    className,
    withTooltip: false,
    counterCss: counterCss,
    wishlistProductCount: fdrIconData?.fdrWishlistsProductCount?.productCount,
    onClick: handleWishlistIconClick,
  };

  return routerLink ? (
    <FdrRoute link={routerLink} shallow={false}>
      <FdrWishlistIcon {...wishListIconProps} />
    </FdrRoute>
  ) : (
    <FdrWishlistIcon {...wishListIconProps} />
  );
};
